<template>
  <div class="home-p-4 home-max-w-[1412px] home-mx-auto home-my-2 home-rounded-lg">
    <div v-if="vehicles.length > 0">
      <div class="home-flex home-flex-wrap home-w-fit home-mx-auto home-gap-[10px]">
        <div
          v-for="(vehicle, index) in vehicles"
          :key="index"
          class="home-flex home-flex-row home-w-full home-max-w-[453px] home-mx-auto lg:home-mx-0 home-bg-white home-p-[10px] home-rounded-lg home-shadow-">
          <div class="home-w-full">
            <div class="home-flex home-flex-row home-w-full home-pb-2 home-border-b-[1px] home-mb-2 home-items-center">
              <div class="home-w-[60px]">
                <img :src="getBrandImageUrl(vehicle.vehicle_data.brand)" :alt="vehicle.vehicle_data.brand" width="50px" height="50px" class="mr-4" />
              </div>
              <div class="">
                <p class="home-text-lg home-font-bold">{{ vehicle.vehicle_data.brand }} {{ vehicle.vehicle_data.series }} {{ vehicle.vehicle_data.engine }}</p>
              </div>
            </div>
            <div class="home-my-2">
              <div class="home-flex home-flex-wrap home-gap-1">
                <div class="home-border-gray-300 home-border-[1px] home-rounded-md home-p-1">
                  <p class="home-text-[13px]"><strong>Build:</strong> {{ vehicle.vehicle_data.build }}</p>
                </div>
                <div class="home-border-gray-300 home-border-[1px] home-rounded-md home-p-1">
                  <p class="home-text-[13px]"><strong>HP:</strong> {{ vehicle.vehicle_data.hp }}</p>
                </div>
                <div class="home-border-gray-300 home-border-[1px] home-rounded-md home-p-1">
                  <p class="home-text-[13px]"><strong>Engine:</strong> {{ vehicle.vehicle_data.engine }}</p>
                </div>
              </div>
            </div>

            <div class="home-text-white home-my-2">
              <div class="home-flex home-flex-wrap home-gap-1">
                <div class="home-bg-[#1e44d9] home-rounded-md home-p-1">
                  <p class="home-text-[12px]"><strong>ECU Brand:</strong> {{ JSON.parse(vehicle.meta_hardware).ecu_brand }}</p>
                </div>
                <div class="home-bg-[#1e44d9] home-rounded-md home-p-1">
                  <p class="home-text-[12px]"><strong>Read Method:</strong> {{ JSON.parse(vehicle.meta_hardware).read_method }}</p>
                </div>
                <div class="home-bg-[#1e44d9] home-rounded-md home-p-1">
                  <p class="home-text-[12px]"><strong>ECU Build:</strong> {{ JSON.parse(vehicle.meta_hardware).ecu_build }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center text-gray-600 text-lg">Working on today's first file</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      vehicles: [],
      website_id: 4,
      date_from: "",
      date_until: "",
      limit: 6,
    };
  },
  created() {
    this.fetchData();
    this.startAutoRefresh();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          limit: this.limit,
        };

        const response = await axios.get("https://crm.ecufiles.com/api/external/files_for_website", {params});
        this.vehicles = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.vehicles = []; // Ensure vehicles array is empty on error
      }
    },
    startAutoRefresh() {
      setInterval(() => {
        this.fetchData();
      }, 600000); // 600000 milliseconds = 10 minutes
    },
    getBrandImageUrl(brand) {
      const formattedBrand = brand.toLowerCase().replace(/\s+/g, "-");
      return `https://www.ecufiles.com/assets/img/brand-logos/${formattedBrand}.webp`;
    },
  },
};
</script>
